import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import { Colors, Fonts, TypeScale, media } from './variables'

import logoDark from '../../images/ao-logo-dark.svg'

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.background};
    color: ${(props) => props.theme.color};
    font-size: ${TypeScale.one};
    line-height: 1.45;
  }

  p {
      display: block;
      margin-top: 0;
      margin-bottom: 1em;
  }

  a {
    color: ${(props) => props.theme.link};

    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.linkHover};
      color: ${(props) => props.theme.background};
      box-shadow: 0 0 0 2px ${(props) => props.theme.linkHover};
      text-decoration: none;
      outline: none;
    }
  }
`

GlobalStyle.defaultProps = {
    theme: {
        background: Colors.light,
        color: Colors.dark,
        hover: Colors.purpleDark,
        border: Colors.dark,
        link: Colors.purpleDark,
        linkHover: Colors.purple,
    }
}

export const Container = styled.div`
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
`

export const SiteHeader = styled.h1`
  display: block;
  margin: 0;

  & > a {
    display: block;
    width: 57px;
    height: 44px;
    background-image: url(${props => props.theme.logo});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;

    &:hover,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      border: 0;
    }

    & > span {
      position: absolute !important;
      height: 1px; width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
    }
  }
`

SiteHeader.defaultProps = {
    theme: {
        logo: logoDark
    }
}

export const Button = styled(Link)`
    display: inline-block;
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none;
    color: ${Colors.light};
    background-color: ${Colors.purpleDark};
    box-shadow: 2px 2px 0 0 ${Colors.darkShadow};
    cursor: pointer;

    &:hover,
    &:focus {
        color: ${Colors.light};
        background-color: ${Colors.purple};
        box-shadow: 2px 2px 0 0 ${Colors.darkShadow};
    }

    &:active {
        position: relative;
        top: 1px;
    }
`

export const ButtonDark = styled(Button)`
	color: ${Colors.dark};
	background-color: ${Colors.orangeDark};
	box-shadow: 2px 2px 0 0 ${Colors.lightShadow};

	&:hover,
	&:focus {
		color: ${Colors.dark};
		background-color: ${Colors.orange};
		box-shadow: 2px 2px 0 0 ${Colors.lightShadow};
	}
`

export const PageHeading = styled.h1`
    font-family: ${Fonts.serif};
    font-size: ${TypeScale.five};
    line-height: 1.5;
    grid-column: 2 / span 13;


    ${media.greaterThan('small')`
        grid-column: 2 / span 10;
        font-size: ${TypeScale.six};
    `}
`

export const PostHeading = styled.h1`
    font-family: ${Fonts.serif};
    font-size: ${TypeScale.five};
    line-height: 1.4;
    grid-column: 2 / span 10;
    margin-bottom: 1em;
`

export const Label = styled.label`
    display: block;
    padding: 0;
    margin: 0 0 5px;
`

export const Input = styled.input`
    display: block;
    width: 100%;
    margin: 0 0 20px;
    padding: .5em .25em;
    border: 0;
`

export const FooterLight = styled.footer`
    margin: 40px 0;
    padding: 40px 0;
    border-top: 6px solid ${Colors.purpleDark};
`

export const FooterDark = styled(FooterLight)`
    border-top-color: ${Colors.orangeDark};
`