import { generateMedia } from 'styled-media-query'

export const Colors = {
    dark: '#262526',
    light: '#F0EDF2',
    purpleDark: '#5B11A6',
    purple: '#A347FF',
    purpleLight: '#CC99FF',
    orangeDark: '#D98F21',
    orange: '#FFB84C',
    orangeLight: '#FFE0B3',
    blue: '#0810B2',
    blueLight: '#99AFFF',

    darkShadow: 'rgba(38,37,38,0.6)',
    lightShadow: 'rgba(240,247,242,0.6)',
}

export const Fonts = {
    serif: '\'Libre Baskerville\', serif',
    sans: '\'PT Sans\', sans-serif',
}

export const TypeScale = {
    zero: '14px',
    one: '18px',
    two: '26px',
    three: '32px',
    four: '38px',
    five: '40px',
    six: '56px',
}

export const media = generateMedia({
    small: '27.5em',
    medium: '48em',
    large: '60em'
})