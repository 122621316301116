import { Link } from 'gatsby'
import React from 'react'
import styled, { ThemeConsumer } from 'styled-components'

import { Container, SiteHeader } from './styles/elements'

import Navigation from './Navigation'

const HeaderContainer = styled(Container)`
  padding: 40px 0;

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const Header = (theme) => (
  <HeaderContainer>
    <header>
      <ThemeConsumer>
        {theme => (
          <SiteHeader><Link to="/"><span>Adam Onishi</span></Link></SiteHeader>
        )}
      </ThemeConsumer>
      
      <Navigation />
    </header>
  </HeaderContainer>
)

export default Header
