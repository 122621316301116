import { Link } from 'gatsby'
import React from 'react'
import styled, { ThemeConsumer } from 'styled-components'

import { Fonts, Colors } from './styles/variables'

const NavWrapper = styled.nav`
    display: flex;
    justify-items: space-between;
    border-bottom: 1px solid ${(props) => props.theme.border};
`

const NavLink = styled(Link)`
    padding-bottom: 2px;
    margin-right: 10px;
    text-decoration: none;
    font-family: ${Fonts.serif};
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.color};
    text-transform: lowercase;
    border-bottom: 5px solid transparent;

    &:hover,
    &:focus {
        color: ${(props) => props.theme.hover};
        background-color: transparent;
        box-shadow: none;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &.active {
        color: ${(props) => props.theme.hover};
        border-bottom-color: ${(props) => props.theme.hover};
    }
`

NavWrapper.defaultProps = {
    theme: {
        border: Colors.dark
    }
}

NavLink.defaultProps = {
    theme: {
        color: Colors.dark,
        hover: Colors.purpleDark,
    }
}

const Navigation = () => (
    <ThemeConsumer>
        {theme => (
            <NavWrapper>
                <NavLink to='/' activeClassName='active'>Home</NavLink>
                <NavLink to='/about' partiallyActive={true} activeClassName='active'>About</NavLink>
                {/* <NavLink to='/photography' partiallyActive={true} activeClassName='active'>Photography</NavLink> */}
                <NavLink to="/articles" partiallyActive={true} activeClassName="active">Articles</NavLink>
                <NavLink to='/contact' partiallyActive={true} activeClassName='active'>Contact</NavLink>
            </NavWrapper>
        )}
    </ThemeConsumer>
)

export default Navigation